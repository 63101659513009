<template>
  <div class="nuevoCaso">
    <div class="flex flex-col md:flex-row">
      <div class="main-content flex-1 bg-gray-100 mt-12 md:mt-12 pb-24 md:pb-5">
        <div class="bg-gray-800 pt-3">
          <div
            class="
              rounded-tl-3xl
              bg-gradient-to-r
              from-blue-900
              to-gray-800
              p-4
              shadow
              text-2xl text-white
            "
          >
            <h3 class="font-bold pl-2 font-sans">Nuevo caso</h3>
          </div>
        </div>
        <div class="bg-gray-100 mx-auto max-w-6xl shadow-xl">
          <div
            v-if="showModal"
            class="
              overflow-x-hidden overflow-y-auto
              fixed
              inset-0
              z-50
              outline-none
              focus:outline-none
              justify-center
              items-center
              flex
            "
          >
            <div class="relative w-auto my-6 mx-auto max-w-6xl">
              <!--content-->
              <div
                class="
                  border-0
                  rounded-3xl
                  shadow-lg
                  relative
                  flex flex-col
                  w-full
                  bg-white
                  outline-none
                  focus:outline-none
                "
              >
                <!--header-->
                <div
                  class="
                    text-center
                    p-3
                    border-b
                    rounded-t-3xl
                    bg-gradient-to-r
                    from-blue-900
                    to-gray-800
                    text-white
                  "
                >
                  <h3
                    class="
                      inline-block
                      align-middle
                      text-2xl
                      font-semibold
                      text-center
                    "
                  >
                    Cambios y devoluciones
                  </h3>
                </div>
                <!--body-->
                <div class="relative p-6 flex-auto">
                  <div class="grid grid-cols-5 text-left">
                    <div>
                      <label class="inline-flex items-center">
                        <input
                          type="radio"
                          class="form-radio"
                          name="radio"
                          value="1"
                          :disabled="conFactura"
                          checked
                          @change="mostrarCambios()"
                        />
                        <span class="ml-2 text-gray-500">Cambios</span>
                      </label>
                      <br />
                      <label class="inline-flex items-center">
                        <input
                          type="radio"
                          class="form-radio"
                          name="radio"
                          value="2"
                          :disabled="conFactura"
                          @change="mostrarDevoluciones()"
                        />
                        <span class="ml-2 text-gray-500">Devoluciones</span>
                      </label>
                    </div>
                    <div>
                      <div class="shadow-md flex border">
                        <input
                          v-model="numFactura"
                          class="rounded p-2"
                          type="text"
                          placeholder="Número de factura"
                          @keypress.enter="cargarFactura()"
                        />
                        <button
                          @click="cargarFactura()"
                          class="
                            bg-white
                            w-auto
                            flex
                            justify-end
                            items-center
                            text-blue-900
                            p-2
                            hover:text-gray-800
                          "
                        >
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="w-1/5 text-left mt-5 mb-5"></div>
                  <div class="grid grid-cols-1 md:grid-cols-2">
                    <div class="md:mr-2">
                      <table class="w-full shadow-xl border-2 table-fixed">
                        <thead class="bg-blue-900 text-white">
                          <tr>
                            <th class="w-1/4 border-2">Código</th>
                            <th class="w-full border-2">Descripción</th>
                            <th class="w-1/5 border-2">Cant</th>
                          </tr>
                        </thead>
                        <tbody class="border">
                          <tr
                            @click="
                              $set(item, 'selected', true), agregarCodigo(item)
                            "
                            v-bind:key="item.codigo"
                            :class="{ highlight: item.selected }"
                            v-for="item of factura"
                            :id="item.codigo"
                            class=" hover:bg-gray-200 cursor-pointer"
                          >
                            <td>{{ item.codigo }}</td>
                            <td>{{ item.descripcion }}</td>
                            <td>{{ item.cantidad }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="md:ml-2">
                      <!-- Cambios derechos-->
                      <table v-if="cambios" class="shadow-xl w-full border ">
                        <thead class="bg-blue-900 text-white">
                          <tr>
                            <th class="w-1/7 hidden border">ID</th>
                            <th class="w-1/7 border">Borrar</th>
                            <th class="w-1/4 border">Entrantes</th>
                            <th class="w-3/4 border">Causal</th>
                            <th class="w-1/4 border">Cantidad</th>
                          </tr>
                        </thead>
                        <tbody class="border">
                          <tr
                            v-bind:key="index"
                            v-for="(item, index) of codsCambioAux"
                            class="border"
                          >
                            <td class="hidden">{{ item.id }}</td>
                            <td>
                              <i
                                @click="
                                  quitarCodigo(index, item.Codigoproducto)
                                "
                                class="fas text-lg text-red-400 cursor-pointer"
                                >&#xf2ed;</i
                              >
                            </td>
                            <td>{{ item.Codigoproducto }}</td>

                            <td class="">
                              <select
                                :id="'CodCausal' + index"
                                class="form-control w-full"
                                @change="setIdCausal('CodCausal' + index)"
                              >
                                <option value="0">Sin causal</option>
                                <option
                                  v-for="data in datas"
                                  :value="data.idTiposCausales"
                                  :key="data.idTiposCausales"
                                >
                                  {{ data.descripcionCausal }}
                                </option>
                              </select>
                            </td>
                            <td>
                              <label class="disabled w-full text-center">{{
                                1
                              }}</label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!--Div central  -->
                      <div
                        v-if="cambios && numFactura != '' && codsCambioAux != 0"
                        class="grid grid-cols-4 mt-10 bg-gray-100 p-1 rounded"
                      >
                        <div class="col-span-2">
                          <label for="codSaliente" class="font-bold text-left"
                            >Codigo Saliente * :</label
                          >
                          <input
                            v-model="codSaliente"
                            id="codSaliente"
                            type="text"
                            class="
                              bg-white
                              border
                              hover:border-blue-400
                              p-1
                              rounded
                              w-24
                            "
                            placeholder="Codigo"
                            @keypress.enter="agregarCodsSalientes()"
                          />
                        </div>
                        <div>
                          <label for="codSaliente" class="font-bold text-left"
                            >Cantidad * :</label
                          >
                          <input
                            v-model="cantSaliente"
                            disabled
                            id="codSaliente"
                            type="number"
                            class="
                              bg-white
                              border
                              hover:border-green-400
                              p-1
                              rounded
                              w-8
                            "
                            placeholder="#"
                          />
                        </div>
                        <div>
                          <button
                            id="agregar"
                            class="
                              text-white
                              bg-[#1e3a8a]
                              border
                              font-bold
                              shadow
                              py-1
                              px-2
                              rounded
                              mt-1
                            "
                            @click="agregarCodsSalientes()"
                          >
                          <i class="fas fa-plus pr-2"></i>Agregar
                          </button>
                        </div>
                      </div>
                      <!--Div central  -->
                      <table v-if="cambios" class="shadow-xl w-full mt-2">
                        <thead class="bg-blue-900 text-white">
                          <tr>
                            <th class="w-1/12 hidden border">ID</th>
                            <th class="w-1/12 border">Borrar</th>
                            <th class="w-2/12 border">Salientes</th>
                            <th class="w-6/12 border">Descripcion</th>
                            <th class="w-2/12 border">Cantidad</th>
                          </tr>
                        </thead>
                        <tbody class="border">
                          <tr
                            v-bind:key="index"
                            v-for="(item, index) of codsCambioSalientes"
                            class="border"
                          >
                            <td class="hidden">{{ item.id }}</td>
                            <td>
                              <i
                                @click="
                                  quitarCodigoSaliente(
                                    index,
                                    item.Codigoproducto
                                  )
                                "
                                class="fas text-lg text-red-400 cursor-pointer"
                                >&#xf2ed;</i
                              >
                            </td>
                            <td>
                              <input
                                disabled
                                :id="'entrante' + item.id"
                                class="border w-2/3"
                                type="text"
                                :value="item.Codigoproducto"
                                @keyup="
                                  cargarCodigo(
                                    item.CodigoproductoEntrante,
                                    item.id
                                  )
                                "
                              />
                            </td>
                            <td>
                              <label class="disabled w-full text-center">{{
                                item.descripcionProducto
                              }}</label>
                            </td>
                            <td>
                              <label class="disabled w-full text-center">{{
                                1
                              }}</label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- Devs derechos-->

                      <table v-if="devoluciones" class="shadow-xl w-full">
                        <thead class=" bg-blue-900 text-white">
                          <tr>
                            <th class="w-2/12 border">Borrar</th>
                            <th class="w-2/12 border">Entrantes</th>
                            <th class="w-6/12 border">Causal</th>
                            <th class="w-2/12 border">Cantidad</th>
                          </tr>
                        </thead>
                        <tbody class="border">
                          <tr
                            v-bind:key="index"
                            v-for="(item, index) of codsDev"
                            class="border"
                          >
                            <td>
                              <i
                                @click="
                                  quitarCodigo(index, item.Codigoproducto)
                                "
                                class="fas text-lg text-red-400 cursor-pointer"
                                >&#xf2ed;</i
                              >
                            </td>
                            <td>{{ item.Codigoproducto }}</td>
                            <td class="">
                              <select
                                :id="'CodCausal' + index"
                                class="form-control w-full"
                              >
                                <option value="0">Sin causal</option>
                                <option
                                  v-for="data in datas"
                                  :value="data.idTiposCausales"
                                  :key="data.idTiposCausales"
                                >
                                  {{ data.descripcionCausal }}
                                </option>
                              </select>
                            </td>
                            <td>
                              <label class="disabled w-full text-center">{{
                                1
                              }}</label>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div v-if="cambios && numFactura != '' && codsCambioAux != 0" class="cont_ValoresCambios grid grid-cols-3">
                  <div class="relative">
                    <span class="">{{ "Total Entrantes: " }}</span>
                    <span class="text-green-400">{{ ValorTotalCambiosEntrantes }}</span>
                  </div>
                  <div class="relative">
                    <span class="">{{ "Total Salientes: " }}</span>
                    <span class="text-red-400">{{ ValorTotalCambiosSalientes }}</span>
                  </div>
                  <div class="relative">
                    <span class="">{{ "Total Diferencias: " }}</span>
                    <span :class="('text-' + (ValorTotalDiferenciasCambiosEntrantesYSalientes.substr(0,1) == '-' ? 'red' : 'green') + '-400')">{{ ValorTotalDiferenciasCambiosEntrantesYSalientes }}</span>
                  </div>
                </div>
                <!--footer-->
                <div
                  class="
                    flex
                    items-center
                    justify-end
                    p-6
                    border-t border-solid border-blueGray-200
                    rounded-b
                  "
                >
                  <button
                    class="
                      buttonCancel
                      border-blue-900
                      rounded
                      text-blue-900
                      h-9
                      background-transparent
                      border
                      font-bold
                      px-4
                      text-sm
                      mr-1
                      ease-linear
                      transition-all
                      duration-150
                    "
                    type="button"
                    v-on:click="limpiar()"
                  >
                    Cancelar
                  </button>
                  <button
                    class="
                      buttonBlue
                      bg-transparent
                      border border-solid border-blue-500
                      hover:bg-blue-800
                      active:bg-red-600
                      font-bold
                      text-sm
                      px-6
                      py-3
                      rounded
                      outline-none
                      focus:outline-none
                      mr-1
                      mb-1
                      ease-linear
                      transition-all
                      duration-150
                    "
                    type="button"
                    v-on:click="cargarCantidades()"
                  >
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="showModal"
            class="opacity-25 fixed inset-0 z-40 bg-black"
          ></div>

          <div>
            <div
              class="
                bg-white
                shadow-md
                rounded-lg
                px-8
                pt-6
                pb-8
                mb-4
                flex flex-col
              "
            >
              <div class="mt-12 md:mt-1 -mx-3 md:flex mb-6">
                <div class="md:w-1/6 px-2 mb-6 md:mb-0">
                  <label
                    class="
                      uppercase
                      tracking-wide
                      text-black text-xs
                      font-bold
                      mb-2
                    "
                  >
                    Cédula*
                  </label>
                  <input
                    v-model="cedula"
                    @keypress.enter="getCliente()"
                    class="
                      w-8/12
                      md:w-full
                      bg-gray-200
                      text-gray-500
                      border border-gray-200
                      rounded-lg
                      py-1
                      px-2
                      mb-3
                    "
                    id="idCedula"
                    type="text"
                    placeholder="Cédula"
                  />
                  <button
                      @click="getCliente()"
                      class="w-1/12 inline-flex md:hidden buttonSearch"
                    >
                    <i class="fas fa-search"></i>
                  </button>
                </div>
                <div class="md:w-3/6 px-2">
                  <label
                    class="
                      uppercase
                      tracking-wide
                      text-gray-800 text-xs
                      font-bold
                      mb-2
                    "
                    for="title"
                  >
                    Nombre completo
                  </label>
                  <input
                    v-model="nombre"
                    disabled
                    class="
                      w-full
                      bg-gray-200
                      text-gray-500
                      border border-gray-200
                      rounded-lg
                      py-1
                      px-2
                      mb-3
                    "
                    id="title"
                    type="text"
                    placeholder="Nombre completo"
                  />
                </div>
                <div class="md:w-1/6 px-2">
                  <label
                    class="
                      uppercase
                      tracking-wide
                      text-black text-xs
                      font-bold
                      mb-2
                    "
                    for="title"
                  >
                    Zona
                  </label>
                  <input
                    v-model="zona"
                    disabled
                    class="
                      w-full
                      bg-gray-200
                      text-gray-500
                      border border-gray-200
                      rounded-lg
                      py-1
                      px-2
                      mb-3
                    "
                    id="title"
                    type="text"
                    placeholder="Zona"
                  />
                </div>
                <div class="md:w-1/6">
                  <label
                    for="area"
                    class="
                      uppercase
                      tracking-wide
                      text-black text-xs
                      font-bold
                      mb-2
                    "
                    >Campaña</label
                  >
                  <input
                    disabled
                    v-model="campaña"
                    class="
                      w-full
                      bg-gray-200
                      border border-gray-200
                      text-gray-500 text-xs
                      py-2
                      px-3
                      pr-8
                      mb-3
                      rounded-lg
                    "
                  />
                </div>
                <div class="md:w-1/7 text-left px-2 mb-6 md:mb-0">
                  <label
                    class="
                      uppercase
                      tracking-wide
                      text-black
                      ml-3
                      text-xs
                      font-bold
                      mb-2
                    "
                    for="location"
                  >
                    Proceso*
                  </label>
                  <div class="inline-block">
                    <select
                      :disabled="cedulaValidated"
                      @change="
                        tipoReclamo == 114
                          ? (CamDev = 'visible')
                          : (CamDev = 'invisible'),
                          selectUser(tipoReclamo)
                      "
                      v-model="tipoReclamo"
                      class="
                        w-3/7
                        bg-gray-200
                        border border-gray-200
                        text-gray-500 text-xs
                        py-2
                        px-3
                        pr-8
                        mb-3
                        rounded-lg
                      "
                      id="tipoDeCaso"
                    >
                      <option
                        :value="tipo.idTiposReclamosPorProceso"
                        :key="tipo.idTiposReclamosPorProceso"
                        v-for="tipo of tiposDeCaso"
                      >
                        {{ tipo.descripcionProceso }}
                      </option>
                    </select>
                    <button
                      :class="CamDev"
                      @click="toggleModal()"
                      class="w-1/7 buttonBlue ml-2 mb-2"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div :class="CamDev">
                <label class="mr-2"
                  >Productos entrantes:
                  <span class="text-green-400">{{ infoEntrantes }}</span></label
                >
                <label>
                  Productos salientes:
                  <span class="text-red-400">{{ infoSalientes }}</span>
                </label>
              </div>
              <div class="-mx-3 md:flex mb-2">
                <div class="md:w-full px-3">
                  <div class="flex justify-between">
                    <label
                      class="
                        uppercase
                        tracking-wide
                        text-black text-xs
                        font-bold
                        mb-2
                      "
                      for="application-link"
                    >
                      Descripción del caso*
                    </label>

                    <label
                      class="
                        tracking-wide
                        text-gray-500 text-xs
                        font-bold font-sans
                        mb-2
                      "
                      for="application-link"
                    >
                      Estado: Abierto
                    </label>
                  </div>
                  <textarea
                    v-model="descripcion"
                    class="
                      w-full
                      bg-gray-200
                      text-gray-500
                      border border-gray-200
                      rounded-lg
                      py-1
                      px-2
                      mb-3
                    "
                    id="application-link"
                    type="text"
                    placeholder="Descripción detallada sobre el caso.... (obligatorio)"
                  />
                </div>
              </div>
              <div class="mb-2">
                <div class="mx-24 mt-2">
                  <UploadImages
                    fileError="Sólo se aceptan imágenes"
                    clearAll="Borrar todo"
                    @change="handleImages"
                    uploadMsg="Click para subir una imágen o suéltala aquí"
                    maxError="Solo puedes cargar una imágen "
                    :max="1"
                  />
                </div>
              </div>
              <div class="mt-2">
                <div class="md:w-full">
                  <button v-if="!guardando" @click="guardarCaso()" class="buttonBlue inline-block">
                    <i class="fa fa-save text-white pr-2 "
                    ></i
                    ><label exact :style="{ cursor: 'pointer' }" class="font-sans font-medium text-lg">
                      &nbsp;Guardar</label>
                      </button>
                  <button v-else class="buttonGray inline-block">
                    <img class=" relative block h-full mx-auto" src="https://gestioncasos.juanabonita.com.co/img/cargandoJB.gif" alt="">
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import auth from "@/logic/auth";
import UploadImages from "vue-upload-drop-images";
import { mapMutations } from "vuex";

export default {
  name: "NuevoCaso",
  data() {
    return {
      tiposDeCaso: [],
      cedula: "",
      nombre: "",
      zona: "",
      campaña: "",
      caso: [],
      descripcion: "",
      idzona: "",
      tipoReclamo: "",
      ListImagenes: new FormData(),
      fileGrabar: "",
      fileGrabarExt: "",
      base64Aux: "",
      showModal: false,
      CamDev: "invisible",
      factura: [],
      codsCambio: [],
      codsDev: [],
      cambios: true,
      devoluciones: false,
      conFactura: false,
      codSaliente: "",
      infoEntrantes: 0,
      infoSalientes: 0,
      idx: 0,
      numFactura: "",
      iduser: 0,
      datas: [],
      causal: [],
      codsCambioAux: [],
      codsDevAux: [],
      codSaliente: "",
      cantSaliente: 1,
      codsCambioSalientes: [],
      cedulaValidated: true,
      crearCaso: false,
      validacionFactura: [],
      guardando: false,
      subeImg: false,

      idTipoFacturacion: 0,
      listaPrecio: "0",
      idCausal: "0"
    };
  },
  components: { UploadImages },

  methods: {
    ...mapMutations([
      "loadNavbar", 
      "hiddenNavbar", 
      "logueo", 
      "openNavCasos", 
      "closeNavInicio",
      "openModalCargando",
      "closeModalCargando"
    ]),
    selectUser(tiporeclamo) {
      this.tiposDeCaso.forEach((element) => {
        if (element.idTiposReclamosPorProceso == tiporeclamo) {
          this.iduser = element.idusuario;
        }
      });
    },
    handleImages(files) {
      if(files.length > 0){
        var file = files[0]
        var reader = new FileReader();
        reader.onload = () => {
          this.base64Aux = reader.result
            .replace("data:", "")
            .replace(/^.+,/, "");
          this.fileGrabar = files[0];
          this.fileGrabarExt = this.fileGrabar.name.split(".")
          this.subeImg = true
          //console.log(
          //  {
          //    "id": "no se sabe",
          //    "idDetalle": "no se sabe",
          //    "nombreArchivo": "imgCaso." + this.fileGrabarExt[this.fileGrabarExt.length-1],
          //    "base64": this.base64Aux
          //  },
          //)
          //console.log(this.base64Aux)
          //console.log(this.fileGrabar)
      }
      reader.readAsDataURL(file);
      }else{
        this.subeImg = false
      }
    },

    cargarCodigo(entrante, index) {
      var item = document.getElementById("entrante" + index);

      var element = this.codsCambio.find(
        (x) => x.Codigoproducto == entrante && x.id == index
      );

      element.Codigoproducto = item.value;
    },
    /*Eliminar itmes salida */
    quitarCodigoSaliente(index, cod) {
      var cont = 0;
      var id = 0;
      var a = 0;
      this.infoEntrantes = this.codsCambio.length;

      this.infoSalientes = this.codsCambio.reduce(
        (a, b) => a + parseInt(b.CantidadSaliente), 0
      );
      this.codsCambio.forEach(function (item) {
        id++;
        if (item.Codigoproducto == cod) {
          cont++;
          if (cont <= 1) {
            a = id - 1;
          }
        }
      });

      this.codsCambio.splice(a, 1);
      this.codsCambioSalientes = [];
      this.codsCambioAux = [];
      this.codsCambio.forEach((element) => {
        if (element.tipo == "S") {
          this.codsCambioSalientes.push(element);
        } else {
          this.codsCambioAux.push(element);
        }
      });
    },

    /*Eliminar itmes salida */

    quitarCodigo(index, cod) {
      var cont = 0;
      var element = this.factura.find((x) => x.codigo == cod);

      if (this.cambios) {
        var salidascont = 0;
        this.codsCambio.forEach((element) => {
          if (element.tipo == "S") {
            salidascont++;
          }
        });
        this.infoSalientes = salidascont;
        var id = 0;
        var a = 0;
        this.infoEntrantes = this.codsCambio.length - this.infoSalientes;

        this.codsCambio.forEach(function (item) {
          id++;
          if (item.Codigoproducto == cod) {
            cont++;
            if (cont <= 1) {
              a = id - 1;
            }
          }
        });

        if (cont == 1) {
          element.selected = false;
        }
        this.codsCambio.splice(a, 1);
        this.codsCambioSalientes = [];
        this.codsCambioAux = [];
        this.codsCambio.forEach((element) => {
          if (element.tipo == "S") {
            this.codsCambioSalientes.push(element);
          } else {
            this.codsCambioAux.push(element);
          }
        });
      } else {
        this.infoEntrantes = this.codsDev.length;
        var id = 0;
        var a = 0;
        this.infoSalientes = 0;
        this.codsDev.forEach(function (item) {
          id++;
          if (item.Codigoproducto == cod) {
            cont++;
            if (cont <= 1) {
              a = id - 1;
            }
          }
        });
        if (cont == 1) {
          element.selected = false;
        }

        this.codsDev.splice(a, 1);
        //alonso
        //this.idx = this.idx - 1
        this.codsDev.forEach((element) => {
            this.codsDevAux.push(element);
        });
      }
    },
    guardarPostventa(idReclamo) {
      var user = auth.getUserLogged();
      user = JSON.parse(user);

      if (this.cambios) {
        if(this.crearCaso){
          var salidascont = 0;
          this.codsCambio.forEach((element) => {
            if (element.tipo == "S") {
              salidascont++;
            }
          });
          this.infoSalientes = salidascont;
          this.infoEntrantes = this.codsCambio.length - this.infoSalientes;
          this.codsCambio.forEach((element) => {
            element.IdReclamo = idReclamo;
          });
  
          axios
            .post(
              this.$hostname + "/Api/Detalle_Cambios_Devoluciones",
              this.codsCambio,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + user.token,
                },
              }
            )
            .then((result) => {})
            .catch((err) => {
              this.$alert(
                "Ocurrió un error durante el proceso!",
                "Error",
                "error"
              ).then({});
              console.log(err);
            });
        }
      } else {
        if(this.crearCaso){
          this.infoEntrantes = this.codsDev.length;
          this.infoSalientes = 0;
  
          this.codsDev.forEach((element) => {
            element.IdReclamo = idReclamo;
          });
  
          axios
            .post(
              this.$hostname + "/Api/Detalle_Cambios_Devoluciones",
              this.codsDev,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + user.token,
                },
              }
            )
            .then((result) => {})
            .catch((err) => {
              this.$alert(
                "Ocurrió un error durante el proceso!",
                "Error",
                "error"
              ).then({});
              console.log(err);
            });
        }
      }
    },
    getCliente() {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      axios
        .get(this.$hostname + "/Api/Clientes/GetClienteSP/" + this.cedula, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + user.token,
          },
        })
        .then((result) => {
          this.nombre = result.data[0].nombre;
          this.zona = result.data[0].zona;
          this.campaña = result.data[0].campana;
          this.idcliente = result.data[0].numCodigo;
          this.idzona = result.data[0].idzona;
          this.idTipoFacturacion = result.data[0].idTipoFacturacion;
          this.cedulaValidated = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    limpiar() {
      this.toggleModal();
      this.codsCambio = [];
      this.codsDev = [];
      this.factura = [];
      this.conFactura = false;
      this.NumeroFactura = "";
      this.codsCambioAux = [];
      this.codsDevAux = [];
      this.codSaliente = [];
      this.codsCambioSalientes = [];
      this.numFactura = "";
      this.infoEntrantes = 0;
      this.infoSalientes = 0;
      this.cambios = true;
      this.devoluciones = false;
    },
    mostrarCambios() {
      this.cambios = true;
      this.devoluciones = false;
    },
    mostrarDevoluciones() {
      this.cambios = false;
      this.devoluciones = true;
    },
    agregarCodsSalientes() {
      //console.log(this.codsCambio);
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      var camp = this.campaña;
      if (this.codSaliente != "") {
        axios
          .get(
            this.$hostname + 
            "/Api/Detalle_Cambios_Devoluciones/GetCodigoSalienteSp/" + 
            this.codSaliente + 
            "/" + 
            this.factura[0].campaña + 
            "/" + 
            this.idTipoFacturacion + 
            "/" + 
            this.listaPrecio + 
            "/" + 
            this.idCausal, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((result) => {
            //console.log(result.data[0])
            if (result.data.length != 0){
              var producto = {
                id: (this.idx = this.idx + 1),
                Codigoproducto: result.data[0].codigo,
                Cantidad: 1,
                valorUnitarioCliente: result.data[0].valorUnitarioCliente,
                tipo: "S",
                TipoCambioDev: "C",
                Idusuario: user.id,
                Campaña: camp,
                descripcionProducto: result.data[0].descripcion,
                IdReclamo: 0,
                NumeroFactura: this.numFactura,
                CodCausal: "",
                //DescripcionCausal: " a",
                Fecha: new Date(
                  Date.UTC(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    new Date().getDate(),
                    new Date().getHours(),
                    new Date().getMinutes(),
                    new Date().getSeconds()
                  )
                ),
              };
              this.codsCambio.push(producto);
              this.codsCambioSalientes = [];
              this.codsCambioAux = [];
              //console.log(this.codsCambio)
              this.codsCambio.forEach((element) => {
                if (element.tipo == "S") {
                  this.codsCambioSalientes.push(element);
                } else {
                  this.codsCambioAux.push(element);
                }
              });
              this.codSaliente = "";
            }else{
              this.$alert(
                "El codigo ingresado no existe o no pertenece a la campaña " + this.factura[0].campaña + " que corresponde a la factura ingresada",
                "Codigo Saliente Invalido",
                "warning"
              ).then(() => {});
            }
          })
          .catch((err) => {
            console.log(err);
            this.$alert(
                "El codigo ingresado no existe o no pertenece a la campaña " + this.factura[0].campaña + " que corresponde a la factura ingresada",
                "Codigo Saliente Invalido",
                "warning"
              ).then(() => {});
          });
      } else {
        this.$alert(
          "El codigo no debe estar en blanco",
          "Sin Codigo Saliente",
          "warning"
        ).then(() => {});
      }
    },
    agregarCodigo(codigo) {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      var cont = 0;

      var camp = this.campaña;
      if (this.cambios) {
        var salidascont = 0;
        this.codsCambio.forEach((element) => {
          if (element.tipo == "S") {
            salidascont++;
          }
        });
        this.infoSalientes = salidascont;
        this.infoEntrantes = this.codsCambio.length - this.infoSalientes;
        this.codsCambio.forEach(function (element) {
          if (element.Codigoproducto == codigo.codigo) {
            cont++;
          }
        });
        //console.log(cont, codigo.cantidad);

        if (cont < codigo.cantidad) {
          var producto = {
            id: (this.idx = this.idx + 1),
            Codigoproducto: codigo.codigo,
            // descripcion: "",
            Cantidad: 1,
            valorUnitarioCliente: codigo.valorUnitarioCliente,
            tipo: "E",
            TipoCambioDev: "C",
            Idusuario: user.id,
            Campaña: camp,
            descripcionProducto: "desc",
            IdReclamo: 0,
            NumeroFactura: this.numFactura,
            CodCausal: "",
            //DescripcionCausal: " a",
            Fecha: new Date(
              Date.UTC(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                new Date().getHours(),
                new Date().getMinutes(),
                new Date().getSeconds()
              )
            ),
          };
          this.codsCambio.push(producto);

          this.listaPrecio = codigo.strListadePrecio
          this.codsCambioSalientes = [];
          this.codsCambioAux = [];
          this.codsCambio.forEach((element) => {
            if (element.tipo == "S") {
              let info = element;
              this.codsCambioSalientes.push(info);
            } else {
              let info = element;
              this.codsCambioAux.push(info);
            }
          });
          
          console.log(codigo)
          console.log(this.codsCambio)
          console.log(this.codsCambioSalientes)
          console.log(this.codsCambioAux)

          var user = auth.getUserLogged();
          user = JSON.parse(user);
          axios
            .get(
              this.$hostname +
                "/Api/Detalle_Cambios_Devoluciones/GetCausalesSp/" + "CAM",
              //+this.idreclamo,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + user.token,
                },
              }
            )
            .then((res) => {
              this.datas = res.data;
            })

            .catch((err) => {
              this.$alert(
                "Ocurrió un error durante el proceso!",
                "Error",
                "error"
              ).then({});
            });
        } else {
          this.$alert(
            "La cantidad de productos disponible es inferior a la solicitada",
            "Sin cantidad disponible",
            "warning"
          ).then(() => {});
        }
      } else {
        this.infoEntrantes = this.codsDev.length;
        this.infoSalientes = 0;
        this.codsDev.forEach(function (element) {
          if (element.Codigoproducto == codigo.codigo) {
            cont++;
          }
        });
        if (cont < codigo.cantidad) {
          var producto = {
            Codigoproducto: codigo.codigo,
            //descripcion: " ",
            Cantidad: codigo.cantidad,
            valorUnitarioCliente: codigo.valorUnitarioCliente,
            tipo: "E",
            TipoCambioDev: "D",
            Idusuario: user.id,
            Campaña: camp,
            CodCausal: "",
            // DescripcionCausal: " a",
            NumeroFactura: this.numFactura,
            Fecha: new Date(
              Date.UTC(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                new Date().getHours(),
                new Date().getMinutes(),
                new Date().getSeconds()
              )
            ),
          };
          this.codsDev.push(producto);

          this.codsDevAux = []
          this.codsDev.forEach((element) => {
              let info = element;
              this.codsDevAux.push(info);
          });

          var user = auth.getUserLogged();
          user = JSON.parse(user);
          axios
            .get(
              this.$hostname +
                "/Api/Detalle_Cambios_Devoluciones/GetCausalesSp/" + "DEV",
              //+this.idreclamo,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + user.token,
                },
              }
            )
            .then((res) => {
              this.datas = res.data;
            })

            .catch((err) => {
              this.$alert(
                "Ocurrió un error durante el proceso!",
                "Error",
                "error"
              ).then({});
            });
        } else {
          this.$alert(
            "La cantidad de productos disponible es inferior a la solicitada",
            "Sin cantidad disponible",
            "warning"
          ).then(() => {});
        }
      }
    },
    cargarCantidades() {
      if (this.cambios) {
        if(this.ValorTotalDiferenciasCambiosEntrantesYSalientes.substr(0,1) != '-'){
  
          var salidascont = 0;
          this.codsCambio.forEach((element) => {
            if (element.tipo == "S") {
              salidascont++;
            }
          });
          this.infoSalientes = salidascont;
          this.infoEntrantes = this.codsCambio.length - this.infoSalientes;
          var bandera = true;
  
          var conteo = 0;
          this.codsCambio.forEach((element,index) => {
            this.codsCambioSalientes.forEach((element2,index2)=>{
              if(element.tipo == "E"){
                if(element.Codigoproducto == element2.Codigoproducto){
                  this.codsCambio[index].tipo = "S"
                }
              }
            })
          })
          var contador = 0
          var CodCausal = "0"
          this.codsCambio.forEach((element,index) => {
            //console.log('CodCausal' + index)
            if(element.tipo == "E"){
              console.log(contador, index)
              CodCausal = document.getElementById('CodCausal' + contador).options[document.getElementById('CodCausal' + contador).options.selectedIndex].getAttribute('value')
              console.log(CodCausal)
              this.codsCambio[index].CodCausal = CodCausal
              console.log(this.codsCambio[index])
              contador++
            }else{
              this.codsCambio[contador].CodCausal = ""
            }
          })
          contador = 0
          CodCausal = "0"
          this.codsCambio.forEach((element,index) => {
            //console.log('CodCausal' + index)
            if(element.tipo == "E"){
              console.log(contador, index)
              CodCausal = document.getElementById('CodCausal' + contador).options[document.getElementById('CodCausal' + contador).options.selectedIndex].getAttribute('value')
              console.log(CodCausal)
              this.codsCambio[index].CodCausal = CodCausal
              console.log(this.codsCambio[index])
              contador++
            }else{
              this.codsCambio[contador].CodCausal = ""
            }
          })
          this.codsCambio.forEach((element,index) => {
            if (element.Codigoproducto == "") {
              //console.log(element)
              //console.log(1)
              this.$alert(
                "Debe agregar el Codigo de los productos a cambiar",
                "Agregue Los campos correctamente!",
                "warning"
              );
              bandera = false;
            }
            if ((element.CodCausal == "" || element.CodCausal == "0") && element.tipo == "E") {
              //console.log(element)
              //console.log(2)
              this.$alert(
                "Debe agregar el causal y el Codigo de los productos a cambiar",
                "Agregue Los campos correctamente!",
                "warning"
              );
              bandera = false;
            }
            if (element.tipo == "S") {
              conteo = 1;
            }
          });
          if (bandera && conteo == 1) {
            if(this.ValorTotalDiferenciasCambiosEntrantesYSalientes != undefined && this.ValorTotalDiferenciasCambiosEntrantesYSalientes != "$ 0" && this.ValorTotalDiferenciasCambiosEntrantesYSalientes.substr(0,1) != '-' && this.ValorTotalDiferenciasCambiosEntrantesYSalientes != ""){
              this.$confirm("Tienes Una diferencia de " + this.ValorTotalDiferenciasCambiosEntrantesYSalientes + ", Deseas Continuar?").then(() => {
                //do something...
                this.toggleModal();
                this.cambios = true;
                this.devoluciones = false;
              });
            }else{
              this.toggleModal();
              this.cambios = true;
              this.devoluciones = false;
            }
          } else {
            //console.log(element)
            //console.log(3)
            this.$alert(
              "Debe agregar el Codigo y las causales de los productos salientes (por cambiar)",
              "Agregue Los campos correctamente!",
              "warning"
            );
          }
        }else{
          this.$alert(
            "No se pueden realizar cambios por menor valor.",
            "Cambio Por Menor Valor!",
            "warning"
          );
        }
      } else {
        var salidascont = 0;
        this.infoSalientes = salidascont;
        this.infoEntrantes = this.codsDev.length;
        var bandera = true;
        
        var conteo = 0;
        //console.log(this.codsDev)
        //console.log(this.codsDevAux)
        this.codsDev.forEach((element,index) => {
          if(element.tipo = "E"){
            this.codsDev[index].CodCausal = document.getElementById('CodCausal' + index).options[document.getElementById('CodCausal' + index).options.selectedIndex].getAttribute('value')
          }
        })
        this.codsDev.forEach((element,index) => {
          if (element.Codigoproducto == "") {
            //console.log(element)
            //console.log(1)
            this.$alert(
              "Debe agregar el Codigo de los productos a devolver",
              "Agregue Los campos correctamente!",
              "warning"
            );
            bandera = false;
          }
          if ((element.CodCausal == "" || element.CodCausal == "0") && element.tipo == "E") {
            //console.log(element)
            //console.log(2)
            this.$alert(
              "Debe agregar el causal del Codigo de los productos a devolver",
              "Agregue Los campos correctamente!",
              "warning"
            );
            bandera = false;
          }
        });
        //this.infoSalientes = 0;
        //this.toggleModal();
        if (bandera) {
          this.toggleModal();
          this.cambios = false;
          this.devoluciones = true;
        } else {
          //console.log(element)
          //console.log(3)
          this.$alert(
            "Debe agregar el Codigo saliente y las causales de los productos a devolver",
            "Agregue Los campos correctamente!",
            "warning"
          );
        }
        if (this.infoEntrantes > 0) {
          this.infoSalientes = 0;
          this.cambios = false;
          this.devoluciones = true;
        } else {
          //console.log(element)
          //console.log(4)
          this.$alert(
            "Debe agregar minimo 1 codigo",
            "Agregue los codigos a devolver!",
            "warning"
          );
        }
      }
    },
    cargarFactura() {
      this.conFactura = true;
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      let code = this.cambios ? "C" : "D";
      axios
        .get(
          this.$hostname +
            "/Api/Detalle_Cambios_Devoluciones/GetFacturaSp/" +
            this.numFactura +
            "/" +
            this.cedula +
            "/" +
            code,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((result) => {
          this.factura = result.data;
          //console.log(this.factura)          
          if (result.data.length == 0){
            this.conFactura = false;
            this.$alert(
              "La cedula del caso debe coincidir con la factura, Recuerda que solo se pueden hacer cambios a 2 campañas y devoluciones a 1 campaña luego de que la region haya cerrado la digitacion!",
              "Verifique la información",
              "error"
            ).then({});
          }
        })
        .catch((err) => {
          this.$alert(
            "Recuerda que solo se pueden hacer cambios a 2 campañas y devoluciones a 1 campaña luego de que la region haya cerrado la digitacion!",
            "Verifique la información",
            "error"
          ).then({});
          this.conFactura = false;
        });
    },
    enviarCaso(){
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      if(this.crearCaso) {
        //console.log(this.caso)
        if(this.subeImg){
          this.caso.ImagenUrl = "imgCaso." + this.fileGrabarExt[this.fileGrabarExt.length-1]
        }else{
          this.caso.ImagenUrl = ""
        }
        axios
          .post(this.$hostname + "/Api/Reclamos", this.caso, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((result) => {
            if(this.caso.ImagenUrl != ""){
              axios
                .post(
                  this.$hostname +
                    "/Api/Reclamos/uploadProject/",
                    {
                      "id": result.data.data.numcodigo,
                      "idDetalle": 0,
                      "nombreArchivo": this.caso.ImagenUrl,
                      "base64": this.base64Aux
                    },
                  {
                    headers: {
                      'Authorization': "Bearer " + user.token,
                      "Content-Type": "application/json"
                    },
                  }
                )
                .then((result) => {});
            }
            this.guardarPostventa(result.data.data.numcodigo);
            this.$alert(
              "Se generó un caso con #" + result.data.data.numcodigo,
              "Éxito",
              "success"
            ).then((x) => {
              this.$router.push("/Casos/MisCasos");
            });
          });
      }
    },
    cerrarReclamo(){
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      axios
        .get(
          this.$hostname +
            "/Api/Reclamos/CerrarReclamoSp/" +
            this.validacionFactura[0].idreclamo +
            "/" +
            this.validacionFactura[0].email,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          }
        )
        .then((res) => {})
        .catch((err) => {
          this.$alert(
            "Ocurrió un error durante el proceso! Comunicate con el Area de Sistemas (Ext. 150)",
            "Error",
            "error"
          ).then({});
        });
        this.crearCaso = true
        this.enviarCaso()
    },
    guardarCaso() {
      this.guardando = true
      if (this.cedula == "" ){
        this.$alert(
          "Debes ingresar una Cedula para el caso!",
            "Error",
            "error"
        ).then({});
        this.guardando = false
      }else{
        if(this.tipoReclamo == ""){
          this.$alert(
            "Debes seleccionar un Proceso para el caso!",
              "Error",
              "error"
          ).then({});
          this.guardando = false
        }else{
          if(this.descripcion.length === 0){
            this.$alert(
              "El campo de la descripcion no puede dejarse en blanco!",
                "Debes ingresar una descripcion",
                "error"
            ).then({});
            this.guardando = false
          }
          if (this.descripcion.length < 30){
            this.$alert(
              "Debes ingresar una descripcion clara y explicita para el caso!",
                "Error",
                "error"
            ).then({});
            this.guardando = false
          }else{
            let stringImage = "";
            let index = 0;
            var user = auth.getUserLogged();
            user = JSON.parse(user);
      
            this.ListImagenes.forEach((element) => {
              let ext = element.name.split(".");
              stringImage += element.name + ";";
              index++;
            });


            this.caso = {
              idcliente: this.idcliente,
              idZona: this.idzona,
              DescripcionReclamo: this.descripcion,
              IdTiposReclamosPorProceso: this.tipoReclamo,
              IdestadoReclamo: 1,
              IdPrioridad: 1,
              FechaIngreso: new Date(),
              Campaña: this.campaña,
              Idusuario: this.iduser,
              ImagenUrl: "",
              IdusuarioCreacion: user.id,
            };

            this.crearCaso = true

            if(this.cambios){
              if(this.codsCambio.length === 0){
                this.enviarCaso()
              }else{
                this.crearCaso = false
                axios
                  .get(
                    this.$hostname + 
                    "/Api/Detalle_Cambios_Devoluciones/GetDetalleCambiosDevolucionesFactSp/" + 
                    this.numFactura + 
                    "/" + 
                    "C", 
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + user.token,
                      },
                    })
                  .then((result) => {
                    this.validacionFactura = result.data
                    
                    if(this.validacionFactura.length > 0){
                      if(this.validacionFactura[0].estado === 'ASIGNADO'){
                        this.$confirm(
                          "Ya hay un cambio asignado para la factura ingresada, si continuas se cerrara el caso anterior y solo tendremos en cuenta el caso actual ¿Estás seguro que deseas continuar?",
                          "Ya existe un cambio anterior!",
                          "question"
                        )
                          .then(() => {
                            this.cerrarReclamo()
                          })
                          .catch((err) => {
                            console.log(err)
                          });
                      }else{
                        this.crearCaso = true
                        this.enviarCaso()
                      }
                    }else{
                      this.crearCaso = true
                      this.enviarCaso()
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }

            if(this.devoluciones){
              this.crearCaso = false
              axios
                .get(
                  this.$hostname + 
                  "/Api/Detalle_Cambios_Devoluciones/GetDetalleCambiosDevolucionesFactSp/" + 
                  this.numFactura + 
                  "/" + 
                  "D", 
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "Bearer " + user.token,
                    },
                  })
                .then((result) => {
                  this.validacionFactura = result.data

                  if(this.validacionFactura.length > 0){
                    if(this.validacionFactura[0].estado === 'ASIGNADO'){
                      this.$confirm(
                        "Ya hay una devolucion asignada para la factura ingresada, si continuas se cerrara el caso anterior y solo tendremos en cuenta el caso actual ¿Estás seguro que deseas continuar?",
                        "Ya existe una devolucion anterior!",
                        "question"
                      )
                        .then(() => {
                          this.cerrarReclamo()
                        })
                        .catch((err) => {
                          console.log(err)
                        });
                    }else{
                      this.crearCaso = true
                      this.enviarCaso()
                    }
                  }else{
                    this.crearCaso = true
                    this.enviarCaso()
                  }
                })
                .catch((err) => {
                  this.$alert(
                      "Por favor vuelve a ingresar a la plataforma",
                      "Expiro la Sesion!",
                      "error"
                  );
                  this.$router.push("/");
                });
            }
          }
        }
      }
    },
    toggleModal: function () {
      this.showModal = !this.showModal;
    },
    setIdCausal(idElemento) {
      //console.log(idElement)
      let SelectCausales = document.getElementById(idElemento)
      if(SelectCausales != undefined || SelectCausales != null){
        this.idCausal = SelectCausales.value.trim()
      }
    }
  },
  created() {
    this.openNavCasos()
    this.closeNavInicio()
    try {
      var user = auth.getUserLogged();
      user = JSON.parse(user);
      if (user.token == "" || user.token == undefined) {
        this.$router.push("/");
      } else {
        this.display = "block";
        this.usuario = user.usuario;

        this.loadNavbar(user);
        axios
          .get(this.$hostname + "/Api/procesos/GetProcesosSp?id=" + user.id, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + user.token,
            },
          })
          .then((result) => {
            this.tiposDeCaso = result.data;
          })
          .catch((err) => {
            this.$alert(
              "Por favor vuelve a ingresar a la plataforma",
              "Expiro la Sesion!",
              "error"
            );
            this.$router.push("/");
          });
      }
    } catch (err) {
      this.$alert(
        "Por favor vuelve a ingresar a la plataforma",
        "Expiro la Sesion!",
        "error"
      );
      this.$router.push("/");
    }
  },
  computed: {
    //...mapState(["permisosUsuario","infoAgentesWolkvox","MotivosLLamadasCobranza","UbicacionCobranza"]),
    ValorTotalCambiosEntrantes(){
      if(this.codsCambio.filter((el) => {return el.tipo == "E"}).length > 0){
        return new Intl.NumberFormat('es-CO', {style: 'currency',currency: 'COP', maximumSignificantDigits: 9}).format(this.codsCambio.filter((el) => {return el.tipo == "E"}).reduce((acumulador, obj)=>{return acumulador + (obj.Cantidad * obj.valorUnitarioCliente)}, 0))
      }else{
        return ""
      }
    },
    ValorTotalCambiosSalientes(){
      if(this.codsCambio.filter((el) => {return el.tipo == "S"}).length > 0){
        return new Intl.NumberFormat('es-CO', {style: 'currency',currency: 'COP', maximumSignificantDigits: 9}).format(this.codsCambio.filter((el) => {return el.tipo == "S"}).reduce((acumulador, obj)=>{return acumulador + (obj.Cantidad * obj.valorUnitarioCliente)}, 0))
      }else{
        return ""
      }
    },
    ValorTotalDiferenciasCambiosEntrantesYSalientes(){
      var TotalCambiosEntrantes = 0
      var TotalCambiosSalientes = 0
      if(this.codsCambio.filter((el) => {return el.tipo == "E"}).length > 0){
        TotalCambiosEntrantes = this.codsCambio.filter((el) => {return el.tipo == "E"}).reduce((acumulador, obj)=>{return acumulador + (obj.Cantidad * obj.valorUnitarioCliente)}, 0)
      }
      if(this.codsCambio.filter((el) => {return el.tipo == "S"}).length > 0){
        TotalCambiosSalientes = this.codsCambio.filter((el) => {return el.tipo == "S"}).reduce((acumulador, obj)=>{return acumulador + (obj.Cantidad * obj.valorUnitarioCliente)}, 0)
      }
      if(TotalCambiosEntrantes > 0 || TotalCambiosSalientes > 0){
        return new Intl.NumberFormat('es-CO', {style: 'currency',currency: 'COP', maximumSignificantDigits: 9}).format(TotalCambiosSalientes - TotalCambiosEntrantes)
      }else{
        return ""
      }
    }
  }
};
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input[type="submit"],
input[type="button"] {
  -webkit-appearance: none;
  appearance: none;
}

tr.highlight {
  background: yellow;
}

.buttonSearch, 
.button-Search {
  background: #1e3a8a;
  font-weight: bold;
  color: #fff;
  font-size: 0.813em;
  height: 2.3rem;
  line-height: 35px;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);
  outline: none;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
}

.buttonBlue, 
.button-blue {
  display: inline-flex;
  background: #1e3a8a;
  font-weight: bold;
  color: #fff;
  font-size: 0.813em;
  padding: 0 20px;
  height: 2.3rem;
  line-height: 35px;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 0 3px rgb(0 0 0 / 10%);
  outline: none;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  align-content: center;
  flex-wrap: nowrap;
}

.buttonGray,
.button-Gray {
  transform: scale(1.5);
}
.buttonGray:focus,
.button-Gray:focus {
  outline: none;
}
.container {
  width: 80%;
  background: #f7fafc;
  border: 0.5px solid #a3a8b1;
  border-radius: 10px;
  top: 40%;
  left: 0%;
  transform: translate(0%, 0%);
  /* padding: 30px; */
  position: relative;
}
i.fas.fa-sync-alt {
  transform: scale(1.3);
}

.buttonCancel {
  height: 36.8px;
  border-radius: 10px;
}

button#agregar {
    background: #1e3a8a;
    border-radius: 10px;
}
</style>